
import { createApp } from "vue/dist/vue.esm-bundler";
import { ref } from "vue";
import dateFns from 'date-fns'
import AirbnbStyleDatepicker from '../components/AirbnbStyleDatepicker.vue'


document.addEventListener('turbo:load', () => {
  var element = document.getElementById("date-range-picker")
  if (element != null) {
    const datePickerApp = createApp({
      el: '#date-range-picker',
      setup() {
        const dateFormat = 'MMM D'
        const inputDateOne  = ref('')
        const inputDateTwo  = ref('')
        const buttonDateOne = ref('')
        const buttonDateTwo = ref('')
        const inlineDateOne = ref('')
        const sundayFirst   = ref(true)
        const startOpen     = ref(false)
        const alignRight    = ref(false)
        const trigger       = ref(false)
        const colors = {
            selected:       '#2a8cb7',
            inRange:        '#88c8e3',
            selectedText:   '#fff',
            text:           '#565a5c',
            inRangeBorder: '#33dacd',
            disabled:       '#fff',
            hoveredInRange: '#67f6ee'
        }

        return {
          dateFormat,
          inputDateOne,
          inputDateTwo,
          buttonDateOne,
          buttonDateTwo,
          inlineDateOne,
          sundayFirst,
          startOpen,
          alignRight,
          trigger,
          colors
        }
      },
      template: "#date-picker-app-template",
      created() {
        this.buttonDateOne = currentBeginDate;
        this.buttonDateTwo = currentEndDate;
        this.inputDateOne  = currentBeginDate;
        this.inputDateTwo  = currentEndDate;
      },
      methods: {
        formatDates: function(dateOne, dateTwo) {
          var formattedDates = ''
          if (dateOne) {
            formattedDates = dateFns.format(dateOne, this.dateFormat)
            $('#begin_date_view').html(dateFns.format(dateOne, "dddd, MMMM D"))
            $('#begin_date').val(dateOne)
          } else {
            $('#begin_date_view').html("")
            $('#begin_date').val("")
          }
          if (dateTwo) {
            formattedDates += ' - ' + dateFns.format(dateTwo, this.dateFormat)
            $('#end_date_view').html(dateFns.format(dateTwo, "dddd, MMMM D"))
            $('#end_date').val(dateTwo)
          } else {
            $('#end_date_view').html("")
            $('#end_date').val("")
          }
          if ($('#begin_date').val() != $('#prior_begin_date').val() || $('#end_date').val() != $('#prior_end_date').val()) {
            update_pricing()
          }
          return formattedDates
        },
        onClosed: function() {
          var datesStr = this.formatDates(this.buttonDateOne, this.buttonDateTwo)
          this.trigger = false
        },
        onCancelled: function() {
          console.log('onCancelled called')
        },
        toggleAlign: function() {
          this.alignRight = !this.alignRight
        },
        triggerDatepicker: function() {
          this.trigger = !this.trigger
        },
        onMonthChange: function(dates) {
          // console.log('months changed', dates)
        },
      },
    })

    datePickerApp.component('AirbnbStyleDatepicker', AirbnbStyleDatepicker)
    datePickerApp.mount('#date-range-picker')
  }
})
