export default {
  updated: resizeSelect,
  mounted: resizeSelect,
}

function resizeSelect(el, binding, vnode) {
  const select = document.createElement('select')
  select.className = el.className

  const option = document.createElement('option')
  option.textContent = el.value

  select.appendChild(option)
  el.parentNode.appendChild(select)

  const wrapper = select.closest(".asd__wrapper")
  const wrapperOriginalDisplay = wrapper.style.display
  wrapper.style.display = 'inline-block'

  el.style.width = select.offsetWidth + 'px'

  wrapper.style.display = wrapperOriginalDisplay
  select.parentNode.removeChild(select)
}
